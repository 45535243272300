var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "login d-flex justify-center" },
    [
      _c(
        "v-dialog",
        {
          attrs: { width: "500", persistent: "" },
          model: {
            value: _vm.verifyModel,
            callback: function ($$v) {
              _vm.verifyModel = $$v
            },
            expression: "verifyModel",
          },
        },
        [
          _c(
            "v-card",
            [
              _c("v-card-title", { staticClass: "primarybase white--text" }, [
                _vm._v(" " + _vm._s(_vm.verifyTitle) + " "),
              ]),
              _c(
                "v-card-text",
                { staticClass: "py-0" },
                [
                  _c("label", { staticClass: "black--text mb-3 mt-4 d-flex" }, [
                    _vm._v("Enter your email Address"),
                  ]),
                  _c("v-text-field", {
                    attrs: {
                      required: "",
                      outlined: "",
                      dense: "",
                      rules: _vm.emailRules,
                    },
                    model: {
                      value: _vm.verifyEmail,
                      callback: function ($$v) {
                        _vm.verifyEmail =
                          typeof $$v === "string" ? $$v.trim() : $$v
                      },
                      expression: "verifyEmail",
                    },
                  }),
                ],
                1
              ),
              _c("v-divider"),
              _c(
                "v-card-actions",
                [
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    {
                      attrs: {
                        color: "primary",
                        text: "",
                        disabled: !/.+@.+\..+/.test(_vm.verifyEmail),
                      },
                      on: { click: _vm.verifyOk },
                    },
                    [_vm._v(" Ok ")]
                  ),
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "primary", text: "" },
                      on: { click: _vm.verifyCancel },
                    },
                    [_vm._v("Cancel ")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          attrs: { width: "300", persistent: "" },
          model: {
            value: _vm.infoModel,
            callback: function ($$v) {
              _vm.infoModel = $$v
            },
            expression: "infoModel",
          },
        },
        [
          _c(
            "v-card",
            { staticClass: "d-flex align-center justify-center flex-column" },
            [
              _c(
                "v-card-title",
                {
                  staticClass:
                    "d-flex justify-space-between align-center w-100 primary white--text px-6 py-3",
                },
                [_c("h5", [_vm._v(_vm._s(_vm.infoTitle))])]
              ),
              _c(
                "v-card-text",
                { staticClass: "d-flex align-start px-6 py-6" },
                [
                  _c("v-card-title", { staticClass: "text-subtitle-1 pa-0" }, [
                    _vm._v(" " + _vm._s(_vm.infoMessage) + " "),
                  ]),
                ],
                1
              ),
              _c(
                "v-card-actions",
                {
                  staticClass:
                    "d-flex justify-end align-center w-100 pb-4 pt-0 px-6",
                },
                [
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    {
                      attrs: { color: "primary" },
                      on: {
                        click: function ($event) {
                          _vm.infoModel = false
                        },
                      },
                    },
                    [_vm._v(" Ok ")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-main",
        { staticClass: "d-flex" },
        [
          _c(
            "v-card",
            { staticClass: "signin-outer primarybase margin-auto" },
            [
              _c("v-img", {
                attrs: {
                  "max-width": "164",
                  src: require("../assets/images/poweredby-light.svg"),
                },
              }),
              _c(
                "v-form",
                {
                  attrs: { action: _vm.action, method: "POST" },
                  model: {
                    value: _vm.valid,
                    callback: function ($$v) {
                      _vm.valid = $$v
                    },
                    expression: "valid",
                  },
                },
                [
                  _c("input", {
                    attrs: { type: "hidden", name: "csrf_token" },
                    domProps: { value: _vm.csrf_token },
                  }),
                  _c(
                    "v-container",
                    [
                      _c(
                        "v-row",
                        { staticClass: "pt-15 pb-12" },
                        [
                          _c(
                            "v-col",
                            {
                              staticClass: "position-relative py-0",
                              attrs: { cols: "12" },
                            },
                            [
                              _c("label", { staticClass: "sticky-label" }, [
                                _vm._v("Email address"),
                              ]),
                              _c("v-text-field", {
                                staticClass: "rounded-0",
                                attrs: {
                                  name: "password_identifier",
                                  required: "",
                                  outlined: "",
                                  "append-icon": "mdi-check",
                                  dense: "",
                                  rules: _vm.emailRules,
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.close()
                                  },
                                },
                                model: {
                                  value: _vm.email,
                                  callback: function ($$v) {
                                    _vm.email =
                                      typeof $$v === "string" ? $$v.trim() : $$v
                                  },
                                  expression: "email",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            {
                              staticClass: "position-relative py-0",
                              attrs: { cols: "12" },
                            },
                            [
                              _c("label", { staticClass: "sticky-label" }, [
                                _vm._v("Password"),
                              ]),
                              _c("v-text-field", {
                                staticClass: "rounded-0",
                                attrs: {
                                  min: "6",
                                  "append-icon": _vm.toggle
                                    ? "mdi-eye-outline"
                                    : "mdi-eye-off-outline",
                                  type: _vm.toggle ? "text" : "password",
                                  required: "",
                                  outlined: "",
                                  dense: "",
                                  name: "password",
                                  rules: _vm.nameRules,
                                },
                                on: {
                                  "click:append": function ($event) {
                                    _vm.toggle = !_vm.toggle
                                  },
                                  click: function ($event) {
                                    return _vm.close()
                                  },
                                },
                                model: {
                                  value: _vm.password,
                                  callback: function ($$v) {
                                    _vm.password = $$v
                                  },
                                  expression: "password",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            { staticClass: "py-0", attrs: { cols: "12" } },
                            [
                              _c(
                                "v-btn",
                                {
                                  staticClass:
                                    "secondarybase white--text w-100 font-14 primarybase--text",
                                  attrs: {
                                    type: "submit",
                                    block: "",
                                    depressed: "",
                                    large: "",
                                    disabled: !_vm.valid,
                                    value: "password",
                                    name: "method",
                                  },
                                },
                                [_vm._v("LOGIN")]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm.showmsg
                ? _c(
                    "v-card",
                    {
                      staticClass:
                        "error-dialog text-center pa-6 pb-12 pt-8 d-flex flex-column justify-center",
                    },
                    [
                      _c(
                        "v-icon",
                        {
                          staticClass: "close",
                          attrs: { color: "white" },
                          on: {
                            click: function ($event) {
                              return _vm.close()
                            },
                          },
                        },
                        [_vm._v("mdi-close")]
                      ),
                      _c("v-icon", { attrs: { color: "white", large: "" } }, [
                        _vm._v("mdi-alert-circle-outline"),
                      ]),
                      _c(
                        "v-card-title",
                        {
                          staticClass:
                            "h6--text white--text d-flex justify-center Whitney-font",
                        },
                        [_vm._v(" Error ")]
                      ),
                      _c(
                        "v-card-text",
                        {
                          staticClass:
                            "body--text-2 white--text text-left Whitney-font pa-0",
                        },
                        [_vm._v(" " + _vm._s(_vm.msg) + " ")]
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _c("div", { staticClass: "v-login-info" }, [
                _c(
                  "p",
                  {
                    staticClass:
                      "mb-4 mt-0 secondary--text font-18 medium-font",
                  },
                  [
                    _c(
                      "a",
                      {
                        staticClass: "secondary--text",
                        on: { click: _vm.verifyAction },
                      },
                      [_vm._v("Forgot password? ")]
                    ),
                  ]
                ),
                _c(
                  "p",
                  { staticClass: "mt-3 white--text font-18 medium-font" },
                  [
                    _vm._v(" Don't have an account? "),
                    _c(
                      "a",
                      {
                        staticClass: "secondary--text",
                        attrs: { href: "#" },
                        on: {
                          click: function ($event) {
                            $event.preventDefault()
                            return _vm.goToRegistration()
                          },
                        },
                      },
                      [_vm._v("Sign Up")]
                    ),
                  ]
                ),
              ]),
            ],
            1
          ),
          _c("v-img", {
            staticClass: "bg-logo",
            attrs: { src: require("../assets/images/bg-logo.svg") },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }