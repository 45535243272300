<template flex>
  <div class="login d-flex justify-center">
    <v-dialog v-model="verifyModel" width="500" persistent>
      <v-card>
        <v-card-title class="primarybase white--text">
          {{ verifyTitle }}
        </v-card-title>

        <v-card-text class="py-0">
          <label class="black--text mb-3 mt-4 d-flex">Enter your email Address</label>
          <v-text-field v-model.trim="verifyEmail" required outlined dense :rules="emailRules"></v-text-field>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="verifyOk" :disabled="!/.+@.+\..+/.test(verifyEmail)"> Ok </v-btn>
          <v-btn color="primary" text @click="verifyCancel">Cancel </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="infoModel" width="300" persistent>
      <v-card class="d-flex align-center justify-center flex-column">
        <v-card-title class="d-flex justify-space-between align-center w-100 primary white--text px-6 py-3">
          <h5>{{ infoTitle }}</h5>
        </v-card-title>
        <v-card-text class="d-flex align-start px-6 py-6">
          <v-card-title class="text-subtitle-1 pa-0">
            {{ infoMessage }}
          </v-card-title>
        </v-card-text>

        <v-card-actions class="d-flex justify-end align-center w-100 pb-4 pt-0 px-6">
          <v-spacer></v-spacer>
          <v-btn color="primary" @click="infoModel = false"> Ok </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-main class="d-flex">
      <v-card class="signin-outer primarybase margin-auto">
        <v-img max-width="164" src="../assets/images/poweredby-light.svg"></v-img>
        <v-form v-model="valid" :action="action" method="POST">
          <input type="hidden" name="csrf_token" :value="csrf_token" />
          <v-container>
            <v-row class="pt-15 pb-12">
              <v-col cols="12" class="position-relative py-0">
                <label class="sticky-label">Email address</label>
                <v-text-field
                  v-model.trim="email"
                  name="password_identifier"
                  required
                  outlined
                  class="rounded-0"
                  append-icon="mdi-check"
                  dense
                  @click="close()"
                  :rules="emailRules"
                ></v-text-field>
              </v-col>

              <v-col cols="12" class="position-relative py-0">
                <label class="sticky-label">Password</label>
                <v-text-field
                  v-model="password"
                  min="6"
                  :append-icon="toggle ? 'mdi-eye-outline' : 'mdi-eye-off-outline'"
                  :type="toggle ? 'text' : 'password'"
                  @click:append="toggle = !toggle"
                  @click="close()"
                  required
                  outlined
                  class="rounded-0"
                  dense
                  name="password"
                  :rules="nameRules"
                ></v-text-field>
              </v-col>

              <v-col cols="12" class="py-0">
                <v-btn
                  type="submit"
                  block
                  depressed
                  large
                  :disabled="!valid"
                  class="secondarybase white--text w-100 font-14 primarybase--text"
                  value="password"
                  name="method"
                  >LOGIN</v-btn
                >
              </v-col>
            </v-row>
          </v-container>
        </v-form>

        <!-- error message dialog  -->
        <v-card class="error-dialog text-center pa-6 pb-12 pt-8 d-flex flex-column justify-center" v-if="showmsg">
          <v-icon color="white" @click="close()" class="close">mdi-close</v-icon>

          <v-icon color="white" large>mdi-alert-circle-outline</v-icon>
          <v-card-title class="h6--text white--text d-flex justify-center Whitney-font"> Error </v-card-title>
          <v-card-text class="body--text-2 white--text text-left Whitney-font pa-0">
            {{ msg }}
          </v-card-text>
        </v-card>

        <div class="v-login-info">
          <p class="mb-4 mt-0 secondary--text font-18 medium-font">
            <a class="secondary--text" @click="verifyAction">Forgot password? </a>
          </p>

          <p class="mt-3 white--text font-18 medium-font">
            Don't have an account?
            <a class="secondary--text" href="#" @click.prevent="goToRegistration()">Sign Up</a>
          </p>
        </div>
      </v-card>
      <v-img class="bg-logo" src="../assets/images/bg-logo.svg"></v-img>
    </v-main>
  </div>
</template>

<script>
import Vue from 'vue';
import * as moment from 'moment';
import { FlowService } from '@services/flow.service';
import { API_ORY_URL } from '@config';
import { SignUpService } from '@services/signup.service';
export default Vue.extend({
  name: 'Signin',

  data() {
    return {
      enableSS: false,
      ory_url: '',
      loadingAlertFlag: false,
      loadingAlertText: 'Fetching security token from Ory...',
      flowErr: true,
      email: '',
      password: '',
      valid: null,
      flow: null,
      action: null,
      action_oidc: null,
      csrf_token: null,
      showmsg: false,
      dialog: false,
      msg: '',
      off: false,
      toggle: false,
      emailRules: [
        (v) => !!v || 'Email is required',
        (v) =>
          /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
            v
          ) || 'Email must be valid',
        (v) => !/\s/.test(v) || 'Email cannot contain spaces',
      ],
      nameRules: [(v) => !!v || 'Password is required', (v) => (v && v.length >= 6) || 'Password must be atleast 6 characters'],
      fullscreenLoader: null,
      verifyMessage: '',
      verifyTitle: 'Recovery Email',
      verifyModel: false,
      verifyEmail: '',
      infoModel: false,
      infoTitle: 'Success',
      infoMessage: 'Please check your mail. Redirecting to login',
      errorMessage: {},
    };
  },
  methods: {
    close() {
      this.showmsg = false;
    },
    async verifyOk() {
      this.fullscreenLoader = this.$loading.show();
      const res = await SignUpService.sendRecoveryLink({
        id: 'UserID',
        email: this.verifyEmail.toString().toLowerCase().trim(),
      }).catch((e) => {
        this.fullscreenLoader.hide();
        this.infoModel = true;
        this.infoTitle = 'Error';
        this.infoMessage = 'Unable to send recovey link. Please try again or contact support@searchadsmaven.com if issue persists';
      });
      this.fullscreenLoader.hide();
      this.verifyModel = false;
      if (res.code === 0) {
        this.infoModel = true;
        this.infoTitle = 'Success';
        this.infoMessage = 'Recovery link sent successfully';
      } else {
        this.infoModel = true;
        this.infoTitle = 'Error';
        this.infoMessage = 'Unable to send recovey link. Please try again or contact support@searchadsmaven.com if issue persists';
      }
    },
    verifyCancel() {
      this.verifyModel = false;
    },
    verifyAction() {
      this.msgModel = false;
      this.verifyModel = true;
      (this.verifyTitle = 'Recovery Email'), (this.verifyMessage = 'Please enter your E-mail to send recovery link');
      return;
    },
    getFlow: async function () {
      this.fullscreenLoader = this.$loading.show();
      this.msg = '';
      this.flow = this.$route.query.flow;
      await FlowService.fetchFlow(this.flow, 'login')
        .then((flowResp) => {
          if (flowResp.error) {
            if (flowResp.error.code === 410) {
              window.location.href = API_ORY_URL + 'self-service/login/browser?refresh=true';
            }
            if (flowResp.error.code === 403) {
              return this.$router.push('/login');
            }
          }
          this.loadingAlertFlag = false;
          this.fullscreenLoader.hide();
          this.flowErr = false;
          this.csrf_token = flowResp.ui.nodes[0].attributes.value;
          this.action = flowResp.ui.action;
          this.flowId = flowResp.id;
          if (flowResp.ui.messages) {
            this.showmsg = true;

            if (flowResp.ui.messages[0].id == 4000006)
              this.msg = 'The email or password you entered is incorrect. Please enter the correct credentials and try again.';
            this.getDialog();
          }
        })
        .catch((err) => {
          this.flowErr = true;
          this.loadingAlertFlag = false;
          this.fullscreenLoader.hide();
          if (err.message === 'Request failed with status code 410') {
            window.location.href = API_ORY_URL + 'self-service/login/browser?refresh=true';
          } else {
            this.$router.push('/login');
          }
        });
    },
    goToRegistration: function () {
      if (this.enableSS) return this.$router.push('signup');
      else window.location.href = 'https://www.searchadsmaven.com/join-the-beta/';
    },
    async getSS() {
      const enableSS = await SignUpService.getSS();
      this.enableSS = enableSS;
    },
    async getDialog() {
      this.errorMessage = {
        date: moment.now(),
        msg: this.msg,
      };
    },
  },
  beforeMount() {
    this.$root.$emit('signInOpen');
    //to access signin page
    const flow = this.$route.query.flow ? this.$route.query.flow : null;
    if (!flow) {
      return this.$router.push('/login');
    }
    this.ory_url = API_ORY_URL + 'self-service/recovery/browser';
    this.getFlow();
    this.getSS();
  },
});
</script>

<style scoped>
.red {
  color: red;
}
.v-application .red {
  background-color: white !important;
}
.v-main {
  height: 100vh;
}

@media only screen and (max-width: 601px) {
  .v-application .v-main {
    padding-left: 0px !important;
  }
}
</style>
